// Libraries

import React from "react"
import styled from "styled-components"
import ReactHtmlParser from "react-html-parser"
import { graphql } from "gatsby"
import { get } from "lodash"

import SEO from "../components/global/SEO"
import PageLayout from "../components/sharedPageLayout"

// Styles

const Page = styled.div``

const PrivacyContentWrapper = styled.div``

const PrivacyPage = props => {
  //Assigning the props value to a variable. So that we avoid writing repeating node the get the adequate props value.
  const privacyPage = props.data.allWordpressPage.nodes[0]

  const pageLayoutProps = {
    ...props,
    title: get(privacyPage, "title"),
    subTitle: get(privacyPage, "custom.cookie_policy_sub_title"),
    image: get(privacyPage, "custom.cookie_images"),
    showDividerInResponsive: true,
    hideImageInResponsive: false,
    headerClassName: "cookie-template-subtitle",
    responsiveDividerYOffset: 243,
    showBackButton: true,
  }

  let privacyContent = get(privacyPage, "custom.cookie_content")

  //create a custom subtitle and content structure. This will have different styling from other pages sub title
  const CONTENT_DETAILS = (
    <PrivacyContentWrapper className="cookie-template-content ipad">
      {privacyContent ? ReactHtmlParser(privacyContent) : ""}
    </PrivacyContentWrapper>
  )

  return (
    <Page>
      <SEO title={pageLayoutProps.title} />
      <PageLayout {...pageLayoutProps}>{CONTENT_DETAILS}</PageLayout>
    </Page>
  )
}

export default PrivacyPage

export const privacy = graphql`
  query privacy {
    allWordpressPage(filter: { slug: { eq: "privacy-policy" } }) {
      nodes {
        title
        custom {
          cookie_policy_sub_title
          cookie_content
          cookie_images {
            image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt_text
            }
          }
        }
      }
    }
  }
`
